


.x-container {
    margin-top:4em;
    
    .x-rest {
        
        margin-top: 2rem;
        
        .x-become {
            padding-left: 16%;
            @include mobile(){
                padding: 0 2em;
            }
            p {
                color: #999999;
                font-size: 15px;
                line-height: 20px;

                
            }
   
    h3 {
        font-size: 3em;
        line-height: 60px;
        font-style: bold;
        @include mobile(){
            font-size: 1.7em;
        line-height: 1.3;
    }
    }
    .x-terra {
        padding: 3rem 0px;
        font-size: 18px;
        // line-height: 15px;
        // line-height: 111%;
        color: #333333;
        @include mobile(){
            padding: 1em 0 2em;
            font-size: 1.2em;
        }

    }
    
    a {
        color: #EA2125;
        font-size: 18px;
    }

     .arrow-left {
        color: #EA2125;
        padding-left: 10px;
        font-size: 18px;
    }
    
}

.image-padd {
    padding-left: 17%;
    
    @include mobile() {
        padding: 0 2em;
        .human {
            // height: 25em;
            // height: 100%;
            
        }
    }
}

}
.x-deliver {
    margin-top: 3rem;
    padding-top: 6rem;
    
    background-color: #F9F9F9;
   
    .food-image {
        padding-left: 15%;
        @include mobile(){
            padding: 0 2em;
        }
    }

    @include mobile() {
        margin: 3rem 0 0;
        padding-top: 1rem;
        .x-mobile {
            display: flex;
            flex-direction: column-reverse;
            .food-img {
                // height: 30rem;
            
            }
        }
    }
    .x-virtual {
        padding-left: 16%;
    @include mobile() {
        padding: 0 2em;
    }
        h3 {
            font-size: 3em;
            // line-height: 60px;
            font-style: bold;
            @include mobile(){
                font-size: 1.6em;
            }
        }
        h3 .pocket {
            color: orange;
        }
        h3 .rants {
            color: #EA2125;
        }
        p {
            opacity: .5;
        }

        div {
            font-size: 18px;
            // line-height: 23px;
            padding-right: 2em;
            font-style: bold;
            color:#333333;
            @include mobile(){
                font-size: 1.2em;
                padding-right: 0;
            }
        }
        

    }
    
    .store-container {
        display: flex;
        padding-top: 2rem;
        gap: 2rem;
        @include mobile() {
            display: flex;
            // flex-direction: ro;
            gap: 1rem;
            img{
               width: 120px;
            }
        }
    }
}
.food-partners {
    padding-left: 8%;
    margin-top: 3em;
    @include mobile(){
        margin-top: 0;
        padding: 0 2em;
    }
}
h3 {
    font-size: 2em;
    @include mobile(){
       font-size: 1.6em;
    }
}
ul {
    padding: 0px;
    li {
        list-style: none;
        line-height: 45px;
    }
    a {
        text-decoration: none;
        font-size: 1.2em;
        color: #000000;
        @include mobile(){
            font-size: 1.1em;
         }
    }
}
}

body{
  &.darkmode{
      $primary: #EA2125;
      $white-color: #3f3d55;
      $black-font-color: #FFFFFF;
      $body-grey-bg:#1B2431;
      $box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 1);
      $orange-color: #ecaa43;
      $lightergrey: rgb(238, 238, 238);
      $grey-color: #888;
      $light-white: #f6f6f6;
      $light-grey:#BBBBBB;
      $lavenderblush: lavenderblush;
      $purple: #5e35b1;
      $light-purple: #ede7f6;
      $very-light-primary: #faf9f9;
      $light-green: #b9f6ca;
      $green: #00c853;
      $yellow: #ffd54f;
      $light-yellow: #3a371b;
      $Light-border: rgba(0, 65, 93, 0.1);
      $red: #dc3545;
      $blue: #007bff;
      
      
      @import './default.scss';    
      @import './mixins.scss';
      @import '../pages/homepage/index.scss';   
      @import '../pages/tos/index.scss';   
      @import '../pages/about/index.scss';
      @import '../components/navbar/nav.scss';    
      @import '../pages/about/index.scss';
      @import '../components/navbar/navmodal.scss';  
      @import '../components/homepages/footer.scss';
      @import '../components/homepages/rest.scss' ;

  }
  &.lightmode{
      $primary: #EA2125;
      $white-color: #fff;
      $black-font-color: #202224;
      $body-grey-bg:#fff;
      $box-shadow:  0px 3px 12px rgba(0, 0, 0, 0.09);
      $orange-color: #e68211;
      $lightergrey: rgb(238, 238, 238);
      $grey-color: #888;
      $light-white: #f6f6f6;
      $light-grey:#BBBBBB;
      $lavenderblush: lavenderblush;
      $purple: #5e35b1;
      $purple: #7281d8;
      $light-purple: #ede7f6;
      $very-light-primary: #faf9f9;
      $light-green: #e8f5e9;
      $green: #00c853;
      $yellow: #ffd54f;
      $light-yellow: #fffde7;
      $Light-border: rgba(0, 65, 93, 0.1);
      $red: #dc3545;
      $blue: #007bff;

      
      @import './default.scss'; 
      @import './mixins.scss';
      @import '../pages/homepage/index.scss';    
      @import '../pages/tos/index.scss';   
      @import '../components/navbar/nav.scss';      
      @import '../pages/about/index.scss';
      @import '../components/navbar/navmodal.scss'; 
      @import '../components/homepages/footer.scss';
      @import '../components/homepages/rest.scss';     

  }
}
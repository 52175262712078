.x-homepage {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // top: 0;
  // bottom: 0;
  // height: 100%;
  // position: absolute;
  // flex-direction: column;
  // height: 110vh;
  
  .ui.grid{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
 
 
  .x-banner {
    background-image: radial-gradient(#f4d422 0%, #dd8126 100%);
    background: #fff url("https://res.cloudinary.com/dnltxw2jt/image/upload/v1733480922/dfngzc5voptezgm5tl4n.png")  no-repeat;
    background-size: 150%;
    flex: 1;
    // height: 100%;
    flex-grow: 1;
    padding-top: 5em;
    @include mobile(){
      background:#000 url("https://res.cloudinary.com/dnltxw2jt/image/upload/v1733481199/cdjwjsdf5hji2rswxzkj.png") no-repeat;
      background-size: 125%;
      position: relative;
    }
    .x-flex{
      @include mobile() {
     flex-direction: column-reverse;
       }
       .x-left {
         padding: 6%;
         h1 {
           // color: $grey-color;
           font-size: 3em;
           font-weight: bold;
           font-family: fontMedium;
          //  line-height: 60px;

           @include mobile() {
             font-size: 1.6em;
        line-height: 1.3;

           }
         }
         .x-banner-desc {
           font-size: 18px;
          font-weight: 200;
          //  line-height: 33px;
      
           margin-top: 2em;
           @include mobile(){
             font-size: 1.2em;
           }
         }
         .store {
           margin-top: 2em;
           :first-child{
             margin-right: 0.5em;
           }
           @include mobile() {
            flex:1 ;
            justify-content: center;
            text-align: center;
          }
           a.x-download {
             img {
               width: 30%;
               @include mobile() {
                 width: 40%;
               }
             }
           }
         }
       }
    }
    .x-right {
      .x-right-thumbnail {
        width: 70%;
        margin: 5em 0;
        @include mobile() {
          width: 100%;
        margin: 2em 0;
      }
      }
    }
  }
  .x-footer{
    text-align: center;
    padding: 2em;
    font-size: 1.2em;
    @include mobile() {
      font-size: 1em;
    }
    a{
      font-size: 2em;
      margin: 0 10px;
        font-size: 1.5em;
        @include mobile() {
        font-size: 1.2em;
      }
    }
  }

  .x-favourite {
    text-align: center;
    @include mobile(){
      padding: 0 3em 3em;
   
    }
    h5{
      padding-top: 4em;
     
    }
    h3{
      font-size: 2em;
      @include mobile(){
          font-size: 1.5em;
      }
    }
  
  }
  .x-how{
    @include mobile(){
      padding: 0 1em ;
    }
    p{
      font-size: 1.1em;
    }
  }
.x-equal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.x-equal h4 {
  text-align: center;
  font-size: 1.3rem;
  padding: 2rem 0px;
  @include mobile(){
    padding: .5em 0;
    margin: 0 ;
  }
}
.x-equal p {
  text-align: center;
}
.cen {
  text-align: center;
  margin-top: 5rem;
  @include mobile() {
    margin-top: 15px;
  }
}


}

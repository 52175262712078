.footer {
    background-color: black;
    // margin-top: 3em;
    // padding-top: 6em;
    // margin: auto;
    .xx-footer {
      padding-top: 4rem;
      @include mobile(){
          padding-top: 0;
      }
    }
    @include mobile() {
        padding-top: 0px;
    }
    .x-left {
        padding-left: 15%;
        @include mobile(){
            padding: 0 2em
        }
        img{
            width: 120px;
            margin-right: 1em;
        }
        @include mobile() {
            display: flex;
            margin-top: 2em;
            // position: relative;
            // right: 5%;
            // gap: 10px;
            // top: 24em;
        }
        
    }

    .xx-flex {
        display: flex;
        gap: 25%;
        position: relative;
        right: 27%;
        @include mobile() {
            display: flex;
            flex-direction: column;
            position: relative;
            left: 11%;
        }
        h4 {
            color: white;
            font-size: 1.5em;
        }
       ul {
           padding: 0px;
           line-height: 45px;
       }
       li {
           list-style: none;
       }
       a {
           color: white;
           font-size: 1.2em;
       }
    }
    .x-policy {
        padding-left:7.5%;
        @include mobile() {
            padding: 0 2em;
        }

      .x-terms {
          @include mobile() {

            //   display: flex;
            //   flex-direction: column-reverse;
          }
      }

      .x-icons {
        margin-top: 7%;
        ul li {
            display: inline-block;
            padding-left: 10px;
            position: relative;
            left: 6em;
        }
          @include mobile() {
            //   position: relative;
              display: flex;
              flex-direction: column-reverse;
            //   top: -130%;
              ul {
                  position: relative;
                  right: 6em;
              }
              ul li {
                  display: inline-block;
                  padding: 0px 7px;
                
                  @include mobile(){
                    padding-left: 0
                }
              }
              p {
                  padding-left: 10px;
                  @include mobile(){
                    padding: 0
                }
              }
              
          }
      }

      .x-office {
        padding-left: 27%;
          @include mobile() {
            position: relative;
            padding-left: 0;
            // top: 80%;
            // left: -23%;
            
          }
      }

        ul {
            padding: 0px;
            line-height: 40px;
        
        }
        li {
            list-style: none;
        }
        a {
            color: #ffff;
            font-size: 1.2em;
        }
        p {
            color: #ffff;
            font-size: 1.2em;
        }
        .icons {
            padding-left: 10%;
        }

        .icons ul li {
            display: inline-block;
            padding-left: 2rem;
            margin-top: 3rem;
        }
    }
    .get-it{
        position: fixed;
        right: 0;
        left: 0;
        width: 100%;
        height: 50px;
        bottom: 0;
        background: #fff;
        z-index: 1024;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1em 1em;
        .x-store{
            display: flex;
            align-items: center;
            justify-content: center;
            i{
                line-height: 0;
                font-size: .8em;
            }
            p{
                margin: 0 0 .4em;
                font-weight: 800;
            }
        }
        img{
            width: 30px;
            height: 30px;
            margin-right: 1em;
        }
    }
}
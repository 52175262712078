.about-us{
  .slick-dots li button{
     background: transparent !important;
  }
  .x-container {
    background: url('https://res.cloudinary.com/dnltxw2jt/image/upload/v1733482213/360/sdyy4twu43fwdkth38an.png') no-repeat;
    background-size: cover;
    // height: 400px;
    padding: 15em 5em 10em;
    // height: 500px;
    display: flex;
    align-items: center;
    h1{
      color: #fff;
      font-size: 3em;
    }
    .x-subtitle{
        font-size: 1.2em;
        color: #aaa;
    }
    p{
      color: #fff; 
    }
    @include mobile(){
    padding: 10em 1.5em 4em;
    justify-content: center;
    h1{
      font-size: 2em;
    }
    }
    .x-subtitle{
      font-size: 1.1em;
  }
  }
  
  .y-about {
    position: relative;
    padding: 21px 56px;
    @include mobile () {
      padding: 21px 1.5em;
  
    }
   
    .main{
      padding-top: 30px;
      padding-bottom: 60px;
    }
  .main2{
    padding-top: 60px;
   #column{
     &:hover{
      .ribbon{
        display: block;
      }
     }
    .ribbon{
      display: none;
    }
   }
   .column{ 
     text-align: center;
     @include mobile(){
       .ribbon{
        display: block !important;
       }
     }
     .overlay {
       display: none;
     }
   }
  }
  .main3{
    padding-top: 60px;
    padding-bottom: 60px;

  }
    .goal{
      padding-top:50px;
  
      p{
        color: grey;
        font-size: 23px;
        @include mobile(){
          font-size: 1.1em;
        }
      }
    
      
      h1{
        font-size: 45px;
        @include mobile(){
          font-size: 2em;
        }
      }
      
    }
    
    .img {
      position: relative;
      right: 0;
      width:770px;
      height:353px;
    }
    div.rectangle {
      background: rgba(255, 204, 77, 1);
        width: 100px;
        height: 4px;
        
        margin-left: 10;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    div.rectangle1 {
      background: rgba(255, 204, 77, 1);
  
        width: 100px;
        height: 4px;
        margin-left: 10;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .texth {
      
      position: absolute;
      background: 0;
      color:black;
      font-size: 15px;
      padding-bottom: 50px;
     
     
    } 
    .who{
      
      margin-top: 2em;
      h1{
        font-size: 45px;
        @include mobile(){
          font-size: 2em;
          margin-top: 2em;
        }
      }
    }
    .who-img{
      @include mobile(){
        margin-top: 1em;
      }
    }
  .meet{
    p {
      color: grey;
      font-size: 16px;
      @include mobile(){
        font-size: 1.1em;
      }
    }
    h1{
      font-size: 40px;
      @include mobile(){
        font-size: 2em;
      }
    }
  }
  .x-left{
    padding-top: 10px;
    p.cl{
      color:#333333;
      font-size: 28px;
      @include mobile(){
        font-size: 1.3em;
      }
    }
    h3.bl{
      color: black;
      font-size: 28px;
      @include mobile(){
        font-size: 1.3em;
      }
    }
    h3{
    // font-family: 'Mulish';
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    // line-height: 15px;
   
    
    }
    
    span{
      color: red;
      font-size:28px;
      @include mobile(){
        font-size: 1em;
      }
    }
    p {
      color:grey;
      font-size: 16px;
      // font-family: 'Mulish';
      font-weight: 400;
      size: 16px;
      line-height: 25px;
      text-align: justify;
    }
  }
  .x-we {
    scroll-behavior: smooth;
    // font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    size: 16px;
    line-height: 25px;
    text-align: justify;
    p {
      color:grey;
      font-size: 16px;
    }
  }
  
  // .a:hover, .b:hover, .c:hover, .d:hover, .e:hover, .f:hover, .g:hover, .h:hover, .i:hover, .j:hover, .k:hover, .l:hover{
  //   background: none;
  //   opacity: none;
  //   overflow: hidden;
  //   position: absolute;
  // }
  // .a:hover + .texta, .b:hover + .textb, .c:hover + .textc, .d:hover + .textd, .e:hover + .texte, .f:hover + .textf, .g:hover + .textg, .h:hover + .texth, .i:hover + .texti , .j:hover + .textj, .k:hover + .textk, .l:hover + .textl {
  //   display: inline-block;
  //   color: white;
  //   font-size: 16px; 
  // }
  //  .texta, .textb, .textc, .textd, .texte, .textf, .textg, .texth, .texti, .textj, .textk, .textl{
  //       display: none;
  //       text-align: center;
  //       // width: 240px;
  //       // height: 294px;
  //       background: rgba(234, 33, 37, 0.5);
  //       font-size: 18px;
  //       // padding-top:100px ;
  //       // position: relative;
  //       position: absolute;
  //       top: 0;
  //       width: 80%;
  //       height: 100%;
  //       z-index: 100;
  //       p{
  //         color: white;
  //       }
  //     }
      
    }
  
}
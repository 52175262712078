.x-privacy{
    padding:10em 10em 10em;
    h1{
        font-size: 3em;
    }
    h2{
        font-size: 2.5em;
    }
    h3{
        font-size: 2em;
    }
    h1,h2,h3{
        font-family: fontBold;
    }

    p{
        font-size: 1.2em;
    }
    li{
        margin-bottom: 1em;
        font-size: 1.2em;
    }
    @include mobile(){
        padding: 8em 2em 2em;
        h1{
            font-size: 2em;
        }
        h2{
            font-size: 1.5em;
        }
        h3{
            font-size: 1.2em;
        }
    }
}
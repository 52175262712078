
.x-nav{
  position: absolute;
  // width: 0;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1024;

}
.x-nav ul{
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  padding: 1.5em 4em;
  align-items: center;
  background: #2A2A32;
  .x-logo{
    width: 100px;
  }
  &.x-mobile{
    display: none;
  }
  @include mobile(){
    &.x-mobile{
      display: block;
    }
  
  padding: 1em 1.3em 1em 0
  }
}
.x-nav ul li:first-of-type{
  margin-right: auto;
  @include mobile(){
    display: block;
    a{
      padding: 0 15px;
      img{
        margin-top: 0.4em;
      }
    }
  }
}
.x-nav ul li:last-of-type{
  
}
.x-nav ul li{
  &.x-mobile{
    display: none;
    button{
      padding: 10px;
      i{
        margin: 0 !important
      };
    }
  }
  @include mobile(){
    display: none;
    &.x-mobile{
      display: block;
    }
  }
}

.x-nav ul li a{
  padding: 2em;
  font-family: 'Avenir-Medium', sans-serif !important;
  color: #fff;
  font-size: 1.2em;
}

.x-nav button{
  font-size: 1.2em;
  color: #555;
  // height: unset;
  font-family: 'Avenir-Medium', sans-serif !important;
  padding: 0.2em 1.5em;
  @include mobile() {
    font-size: 1em;
  }
}